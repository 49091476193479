import { PhoneNumberUtil, PhoneNumberType, PhoneNumber } from "google-libphonenumber";


export function validatePhoneNumber(phoneNumber: string, country?: string){
    try{
        const phoneNumberCheckerInstance = PhoneNumberUtil.getInstance();
        const numberParsed = phoneNumberCheckerInstance.parse(String(phoneNumber), country || "HK");
        return phoneNumberCheckerInstance.isValidNumberForRegion(numberParsed,country || "HK");
    }catch(e){
        return false;
    }
}