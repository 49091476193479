










































































































































































import { Component, Prop, Vue, Ref } from "vue-property-decorator";

// The MDC Components
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import { parse } from "querystring";

@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCSelectOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped
  }
})
export default class ItemForm extends Vue {
  @Prop() action!: "CreateItem" | "ModifyItem";
  @Prop() itemId!: string; // selected item
  @Prop() templateItemId!: string; // selected item template
  @Ref("description") descriptionComponent!: TheMDCTextFieldOutlined;
  @Ref("declaredCurrency") declaredCurrencyComponent!: TheMDCTextFieldOutlined;
  @Ref("declaredValue") declaredValueComponent!: TheMDCTextFieldOutlined;
  @Ref("categoryOption") categoryOptionComponent!: TheMDCSelectOutlined;
  @Ref("weight") weightComponent!: TheMDCTextFieldOutlined;
  @Ref("length") lengthCompoent!: TheMDCTextFieldOutlined;
  @Ref("width") widthComponent!: TheMDCTextFieldOutlined;
  @Ref("height") heightComponent!: TheMDCTextFieldOutlined;
  @Ref("quantity") quantityComponent!: TheMDCTextFieldOutlined;

  private id = parseInt(this.itemId); // itemId == -1 for null, create itemId decrease from 99999999 ...
  private templateId = parseInt(this.templateItemId); // itemId == -1 for null, saved itemId increase from 1 ...

  get thisItem(): Item | null {
    if (this.action === "CreateItem" && this.id === -1) {
      // brand new item
      return null;
    } else {
      return this.$store.getters["shipment/getShipmentItemById"](this.id);
    }
  }
  get thisTemplateItem(): Item | null {
    if (this.templateId === -1) {
      // no template
      return null;
    } else {
      return this.$store.getters["historialInput/otherCourier/getUserSavedItemById"](
        this.templateId
      );
    }
  }

  get isSubprime(): boolean{
    return this.$store.getters["isSubprime"];
  }

  get text() {
    // general place for text strings, may rewrite this in the future
    return {
      title: this.$t("shipment.shipmentForm.itemForm.title"),
      description: this.$t("shipment.shipmentForm.itemForm.description"),
      currency: this.$t("shipment.shipmentForm.itemForm.currency"),
      value: this.$t("shipment.shipmentForm.itemForm.value"),
      categoryOption: this.$t("shipment.shipmentForm.itemForm.categoryOption"),
      weight: this.$t("shipment.shipmentForm.itemForm.weight"),
      length: this.$t("shipment.shipmentForm.itemForm.length"),
      width: this.$t("shipment.shipmentForm.itemForm.width"),
      height: this.$t("shipment.shipmentForm.itemForm.height"),
      quantity: this.$t("shipment.shipmentForm.itemForm.quantity"),

      return: this.$t("shipment.shipmentForm.itemForm.return"),
      createItemSave: this.$t("shipment.shipmentForm.itemForm.createItemSave"),
      createItemAdd: this.$t("shipment.shipmentForm.itemForm.createItemAdd"),

      // helper text
      kg: this.$t("shipment.shipmentForm.itemForm.kg"),
      cm: this.$t("shipment.shipmentForm.itemForm.cm")
    };
  }

  beforeCreate() {
    this.$store.commit("hideNavBar");
    this.$store.commit("hideTitle");
  }

  mounted() {
    // prefill info
    this.preFill();

    // fill in if this item
    if (this.thisItem) {
      this.fillItem(this.thisItem);
    }

    // fill in if this template item
    if (this.thisTemplateItem) {
      this.fillItem(this.thisTemplateItem);
    }
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }

  get hasThisItem(): boolean {
    return this.thisItem != null;
  }

  get categoryMenu(): MenuItem[] {
    const categoryMenu: MenuItem[] = [];
    Object.entries(this.$store.getters["shipment/getItemCategories"]).forEach(
      ([key, category]) => {
        categoryMenu.push({
          key: key,
          value: key,
          expression: category
        });
      }
    );
    return categoryMenu;
  }

  private fillItem(item: Item) {
    this.descriptionComponent.setValue(item.description);
    this.categoryOptionComponent.setSelectedValue(item.category);
    this.weightComponent.setValue(item.actualWeight? item.actualWeight.toString() : "0");
    this.lengthCompoent.setValue(item.length? item.length.toString() : "0");
    this.widthComponent.setValue(item.width? item.width.toString() : "0");
    this.heightComponent.setValue(item.height? item.height.toString() : "0");
    // this.declaredCurrencyComponent.setValue(item.declaredCurrency);
    this.declaredValueComponent.setValue(item.declaredCustomsValue.toString());
    this.quantityComponent.setValue(item.quantity.toString());
  }

  private preFill() {
    this.declaredCurrencyComponent.setValue("HKD");
    this.quantityComponent.setValue("1");
  }

  private createItem() {
    const id = this.id; // for create new item, id === -1, else id === itemId
    const description = this.descriptionComponent.getValue(); // description and sku is using the same column
    const category = this.categoryOptionComponent.getSelectedValue();
    const actualWeight = parseFloat(this.weightComponent.getValue()) || 0.0;
    const length = parseFloat(this.lengthCompoent.getValue()) || 0.0;
    const width = parseFloat(this.widthComponent.getValue()) || 0.0;
    const height = parseFloat(this.heightComponent.getValue()) || 0.0;
    const declaredValue = parseInt(this.declaredValueComponent.getValue());
    const quantity = parseInt(this.quantityComponent.getValue());

    // create Item
    if (category) {
      // check if address is correct
      const item: Item = {
        itemId: id,

        description: description,
        sku: description,
        category: category,
        actualWeight: actualWeight,
        length: length,
        width: width,
        height: height,
        declaredCurrency: "HKD",
        declaredCustomsValue: declaredValue,
        quantity: quantity
      } as Item;

      // create new or modify old item accordingly
      if (this.action === "CreateItem") {
        this.$store.commit("shipment/addShipmentItem", item);
      } else {
        this.$store.commit("shipment/modifyShipmentItem", item);
      }

      this.toLastPage();
    }
  }
  private proceed() {
    this.$store.commit("showLoading");
  }

  toLastPage() {
    this.$store.commit("showLoading");
    this.$router.back();
  }
}
