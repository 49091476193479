




import { Component, Prop, PropSync, Vue, Ref, Model } from 'vue-property-decorator';

@Component({})
export default class ExpressOrderItemBase extends Vue {
  // vuex path to get item filling
  @Prop() itemId!: string;
  @Prop() templateItemId!: string;

  public action: 'CreateItem' | 'ModifyItem' = 'CreateItem';
  public lastTrackingNumber: string = this.itemId;
  public savedItem: ExpressOrderItem = {};

  beforeCreate() {
    this.$store.commit('hideTopBar');
    this.$store.commit('hideNavBar');
    this.$store.commit('hideTitle');
  }

  public setTrackingNumber(trackingNumber: string) {
    this.savedItem.trackingNumber = trackingNumber;
  }
}
