var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-layout-grid express-order-item__container" },
    [
      _c(
        "div",
        { staticClass: "express-order-item__inner mdc-layout-grid__inner" },
        [
          _c(
            "div",
            {
              staticClass:
                "express-order-item-title__container mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
            },
            [
              _c("h3", { staticClass: "item-title" }, [
                _vm._v(_vm._s(_vm.text.title))
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "item-form__container mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
            },
            [
              _c(
                "form",
                {
                  staticClass:
                    "item-form grid-container mdc-layout-grid__inner",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitItemForm()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-form__row item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c("TheMDCButtonOutlined", {
                        ref: "scanQrCode",
                        class: _vm.trackingNumber
                          ? "shipment-form-color--activate"
                          : "shipment-form-color",
                        attrs: {
                          reference: "scanQrCode",
                          event: "goToQrCode",
                          startIcon: "qr_code",
                          height: "75px",
                          label: _vm.trackingNumber
                            ? _vm.trackingNumber
                            : _vm.isDocument
                            ? _vm.$t(
                                "shipment.shipmentForm.shipmentForm.sameDayLabel"
                              )
                            : _vm.$t(
                                "shipment.shipmentForm.shipmentForm.nonSameDayLabel"
                              ),
                          fontSize: "min(1.5rem, 4vw)"
                        },
                        on: { goToQrCode: _vm.goToQrCode }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "item-form__field" },
                        [
                          _c("TheMDCSelectOutlined", {
                            ref: "categoryOption",
                            attrs: {
                              reference: "categoryOption",
                              label: _vm.text.categoryOption,
                              menuList: _vm.categoryMenu,
                              removeEmptyOption: true,
                              event: "set-category-option"
                            },
                            on: {
                              "set-category-option": _vm.setCategoryOption
                            },
                            model: {
                              value: _vm.inputItem.categoryOptionId,
                              callback: function($$v) {
                                _vm.$set(_vm.inputItem, "categoryOptionId", $$v)
                              },
                              expression: "inputItem.categoryOptionId"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inputItem.categoryOptionId == "other",
                          expression: "inputItem.categoryOptionId == 'other'"
                        }
                      ],
                      staticClass:
                        "item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c("TheMDCTextFieldOutlined", {
                        ref: "category",
                        attrs: {
                          reference: "category",
                          label: _vm.text.otherCategory,
                          type: "text",
                          maxlength: 50,
                          showCounter: true
                        },
                        model: {
                          value: _vm.inputItem.category,
                          callback: function($$v) {
                            _vm.$set(_vm.inputItem, "category", $$v)
                          },
                          expression: "inputItem.category"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c("TheMDCSelectOutlined", {
                        ref: "dimensionOption",
                        attrs: {
                          reference: "dimensionOption",
                          label: _vm.text.dimensionOption,
                          removeEmptyOption: true,
                          menuList: _vm.toPickUpStore
                            ? _vm.nonSameDayDimensionMenu
                            : _vm.dimensionMenu
                        },
                        model: {
                          value: _vm.inputItem.dimensionOptionId,
                          callback: function($$v) {
                            _vm.$set(_vm.inputItem, "dimensionOptionId", $$v)
                          },
                          expression: "inputItem.dimensionOptionId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isDocument,
                          expression: "isDocument"
                        }
                      ],
                      staticClass:
                        "item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c("TheMDCSelectOutlined", {
                        ref: "weightOption",
                        attrs: {
                          reference: "weightOption",
                          label: _vm.text.weightOption,
                          removeEmptyOption: true,
                          menuList: _vm.weightMenu
                        },
                        model: {
                          value: _vm.inputItem.weightOptionId,
                          callback: function($$v) {
                            _vm.$set(_vm.inputItem, "weightOptionId", $$v)
                          },
                          expression: "inputItem.weightOptionId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isDocument,
                          expression: "!isDocument"
                        }
                      ],
                      staticClass:
                        "item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c("TheMDCTextFieldOutlined", {
                        ref: "actualWeight",
                        attrs: {
                          reference: "actualWeight",
                          label: [
                            _vm.$t(
                              "shipment.shipmentForm.itemForm.actualWeight"
                            ),
                            _vm.$t("general.leftBracket"),
                            _vm.$t("shipment.shipmentForm.itemForm.twoDecimal"),
                            _vm.$t("general.rightBracket")
                          ].join(""),
                          placeholder: [
                            _vm.$t("shipment.shipmentForm.itemForm.kg"),
                            _vm.$t("general.leftBracket"),
                            _vm.$t("shipment.shipmentForm.itemForm.twoDecimal"),
                            _vm.$t("general.rightBracket")
                          ].join(""),
                          removeEmptyOption: true,
                          type: "decimal",
                          maxNumber: _vm.maxNumberCheck,
                          minNumber: "0"
                        },
                        model: {
                          value: _vm.inputItem.actualWeight,
                          callback: function($$v) {
                            _vm.$set(_vm.inputItem, "actualWeight", $$v)
                          },
                          expression: "inputItem.actualWeight"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isDocument,
                          expression: "isDocument"
                        }
                      ],
                      staticClass:
                        "item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c("TheMDCSelectOutlined", {
                        ref: "temperatureOption",
                        attrs: {
                          reference: "temperatureOption",
                          label: _vm.text.temperatureOption,
                          menuList: _vm.temperatureMenu,
                          removeEmptyOption: true
                        },
                        model: {
                          value: _vm.inputItem.temperatureOptionId,
                          callback: function($$v) {
                            _vm.$set(_vm.inputItem, "temperatureOptionId", $$v)
                          },
                          expression: "inputItem.temperatureOptionId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-form__row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c("TheMDCTextFieldOutlined", {
                        ref: "orderNotes",
                        attrs: {
                          reference: "orderNotes",
                          label: _vm.text.orderNotesPlaceholder,
                          type: "text",
                          isRequired: false,
                          maxlength: 70,
                          showCounter: true
                        },
                        model: {
                          value: _vm.inputItem.orderNotes,
                          callback: function($$v) {
                            _vm.$set(_vm.inputItem, "orderNotes", $$v)
                          },
                          expression: "inputItem.orderNotes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-process-button-row item-form__row item-process-button-row mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "item-form__field item-process-button-container"
                        },
                        [
                          _c("TheMDCButtonShaped", {
                            ref: "return",
                            attrs: {
                              reference: "return",
                              type: "button",
                              width: "100%",
                              label: _vm.text.return,
                              event: "to-shipment"
                            },
                            on: { "to-shipment": _vm.toShipment }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "item-form__field item-process-button-container"
                        },
                        [
                          _c("TheMDCButtonShapedRaised", {
                            ref: "proceed",
                            attrs: {
                              reference: "proceed",
                              type: "submit",
                              width: "100%",
                              label: _vm.hasThisItem
                                ? _vm.text.createItemSave
                                : _vm.text.createItemAdd,
                              isDisabled: !_vm.validateItem
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }