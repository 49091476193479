var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-item__container" }, [
    _c("div", { staticClass: "add-item-title__container" }, [
      _c("h3", { staticClass: "item-title" }, [_vm._v(_vm._s(_vm.text.title))])
    ]),
    _c("div", { staticClass: "item-form__container" }, [
      _c(
        "form",
        {
          staticClass: "item-form grid-container",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createItem()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "item-form__row grid-item-description",
              staticStyle: { "grid-area": "grid-item-description" }
            },
            [
              _c(
                "div",
                { staticClass: "item-form__field" },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "description",
                    attrs: {
                      reference: "description",
                      label: _vm.text.description,
                      isRequired: true
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "item-form__row grid-item-declared-value inner-delared-value-grid-container",
              staticStyle: { "grid-area": "grid-item-declared-value" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-form__field left-item",
                  staticStyle: { "grid-area": "left-item" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "declaredCurrency",
                    attrs: {
                      reference: "declaredCurrency",
                      label: _vm.text.currency,
                      isRequired: true,
                      isDisabled: true
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "item-form__field right-item",
                  staticStyle: { "grid-area": "right-item" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "declaredValue",
                    attrs: {
                      reference: "declaredValue",
                      label: _vm.text.value,
                      isRequired: true,
                      type: "number"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "item-form__row grid-item-category",
              staticStyle: { "grid-area": "grid-item-category" }
            },
            [
              _c(
                "div",
                { staticClass: "item-form__field" },
                [
                  _c("TheMDCSelectOutlined", {
                    ref: "categoryOption",
                    attrs: {
                      reference: "categoryOption",
                      label: _vm.text.categoryOption,
                      isRequired: true,
                      menuList: _vm.categoryMenu
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "item-form__row grid-item-item-dimensions inner-dimension-grid-container",
              staticStyle: { "grid-area": "grid-item-item-dimensions" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-form__field grid-item-weight",
                  staticStyle: { "grid-area": "grid-item-weight" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "weight",
                    attrs: {
                      reference: "weight",
                      label: _vm.text.weight,
                      isRequired: false,
                      type: "decimal",
                      placeholder: _vm.text.kg
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "item-form__field grid-item-length",
                  staticStyle: { "grid-area": "grid-item-length" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "length",
                    attrs: {
                      reference: "length",
                      label: _vm.text.length,
                      isRequired: false,
                      type: "decimal",
                      placeholder: _vm.text.cm
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "item-form__field grid-item-width",
                  staticStyle: { "grid-area": "grid-item-width" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "width",
                    attrs: {
                      reference: "width",
                      label: _vm.text.width,
                      isRequired: false,
                      type: "decimal",
                      placeholder: _vm.text.cm
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "item-form__field grid-item-height",
                  staticStyle: { "grid-area": "grid-item-height" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "height",
                    attrs: {
                      reference: "height",
                      label: _vm.text.height,
                      isRequired: false,
                      type: "decimal",
                      placeholder: _vm.text.cm
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "item-form__field grid-item-quantity",
                  staticStyle: { "grid-area": "grid-item-quantity" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "quantity",
                    attrs: {
                      reference: "quantity",
                      label: _vm.text.quantity,
                      isRequired: true,
                      type: "number"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "item-form__row grid-item-proceed-button inner-button-grid-container",
              staticStyle: { "grid-area": "grid-item-proceed-button" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-form__field left-item",
                  staticStyle: { "grid-area": "left-item", height: "100%" }
                },
                [
                  _c("TheMDCButtonShaped", {
                    ref: "return",
                    attrs: {
                      reference: "return",
                      type: "button",
                      label: _vm.text.return,
                      event: "to-last-page"
                    },
                    on: { "to-last-page": _vm.toLastPage }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "item-form__field right-item",
                  staticStyle: { "grid-area": "right-item", height: "100%" }
                },
                [
                  _c("TheMDCButtonShapedRaised", {
                    ref: "proceed",
                    attrs: {
                      reference: "proceed",
                      event: "proceed",
                      type: "submit",
                      label: _vm.hasThisItem
                        ? _vm.text.createItemSave
                        : _vm.text.createItemAdd
                    },
                    on: { proceed: _vm.proceed }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }