







































































































































































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import ExpressOrderItemPage from './ExpressOrderItemBase.vue';

// The MDC Components
import TheMDCTextFieldOutlined from '@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue';
import TheMDCSelectOutlined from '@/components/mdcComponents/selects/TheMDCSelectOutlined.vue';
import TheMDCButtonOutlined from '@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue';
import TheMDCButtonShapedRaised from '@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue';
import TheMDCButtonShaped from '@/components/mdcComponents/buttons/TheMDCButtonShaped.vue';
import { ShipmentHelpers } from '@/utils/shipmentHelpers';

@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCSelectOutlined,
    TheMDCButtonOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
  },
})
export default class ExpressOrderItemForm extends Vue {
  @Prop() itemId!: string; // selected item
  @Prop() templateItemId!: string; // selected item template

  @Ref('categoryOption') categoryOptionComponent!: TheMDCSelectOutlined;
  @Ref('category') categoryComponent!: TheMDCTextFieldOutlined;
  @Ref('dimensionOption') dimensionComponent!: TheMDCSelectOutlined;
  @Ref('weightOption') weightComponent!: TheMDCSelectOutlined;
  @Ref('actualWeight') actualWeightComponent!: TheMDCTextFieldOutlined;
  @Ref('temperatureOption') temperatureComponent!: TheMDCSelectOutlined;
  @Ref('orderNotes') orderNotesComponent!: TheMDCTextFieldOutlined;

  // @Ref("declaredCurrency") declaredCurrencyComponent!: TheMDCTextFieldOutlined;
  // @Ref("declaredValue") declaredValueComponent!: TheMDCTextFieldOutlined;

  private templateId = parseInt(this.templateItemId); // itemId == -1 for null, saved itemId increase from 1 ...
  private inputItem: ExpressOrderItem = {
    trackingNumber: '',
    categoryOptionId: '',
    category: '',
    dimensionOptionId: '',
    weightOptionId: '',
    actualWeight: '',
    temperatureOptionId: '',
    orderNotes: undefined,
    //  customDeclaredValue : "",
  };

  get importItem(): ExpressOrderItem | null {
    const items = this.$store.getters['shipment/getExpressOrderItems'];
    //console.log(this.itemId);
    //console.log(items.find((expressOrderItem: ExpressOrderItem) => expressOrderItem.trackingNumber == this.itemId));
    return items.find((expressOrderItem: ExpressOrderItem) => expressOrderItem.trackingNumber == this.itemId);
    // if (this.action === "CreateItem" && this.id === -1) {
    //   // brand new item
    //   return null;
    // } else {
    //   return this.$store.getters["shipment/getShipmentItemById"](this.id);
    // }
  }
  get thisTemplateItem(): ExpressOrderItem | null {
    if (this.templateId === -1) {
      // no template
      return null;
    } else {
      return this.$store.getters[
        'historialInput/expressOrder/' + (this.isDocument ? 'getUserSavedSameDayItemById' : 'getUserSavedItemById')
      ](this.templateId);
    }
  }

  get isDocument(): boolean {
    return this.$store.getters['shipment/isDocument'];
  }

  get item(): ExpressOrderItem {
    return this.$parent.$data.savedItem;
  }

  get action(): string {
    return this.$parent.$data.action;
  }

  get lastTrackingNumber(): string {
    return this.$parent.$data.lastTrackingNumber;
  }

  get text() {
    // general place for text strings, may rewrite this in the future
    return {
      title: this.$t('shipment.shipmentForm.itemForm.title'),
      description: this.$t('shipment.shipmentForm.itemForm.description'),
      currency: this.$t('shipment.shipmentForm.itemForm.currency'),
      value: this.$t('shipment.shipmentForm.itemForm.value'),
      categoryOption: this.$t('shipment.shipmentForm.itemForm.categoryOption'),
      otherCategory: this.$t('shipment.shipmentForm.itemForm.otherCategory'),
      dimensionOption: this.$t('shipment.shipmentForm.itemForm.dimensionOption'),
      weightOption: this.$t('shipment.shipmentForm.itemForm.weightOption'),
      actualWeight: this.$t('shipment.shipmentForm.itemForm.actualWeight'),
      temperatureOption: this.$t('shipment.shipmentForm.itemForm.temperatureOption'),
      weight: this.$t('shipment.shipmentForm.itemForm.weight'),
      length: this.$t('shipment.shipmentForm.itemForm.length'),
      width: this.$t('shipment.shipmentForm.itemForm.width'),
      height: this.$t('shipment.shipmentForm.itemForm.height'),
      quantity: this.$t('shipment.shipmentForm.itemForm.quantity'),
      orderNotesPlaceholder: this.$t('shipment.shipmentForm.shipmentForm.orderNotesPlaceholder'),
      return: this.$t('shipment.shipmentForm.itemForm.return'),
      createItemSave: this.$t('shipment.shipmentForm.itemForm.createItemSave'),
      createItemAdd: this.$t('shipment.shipmentForm.itemForm.createItemAdd'),

      // helper text
      kg: this.$t('shipment.shipmentForm.itemForm.kg'),
      cm: this.$t('shipment.shipmentForm.itemForm.cm'),
    };
  }

  beforeCreate() {
    this.$store.commit('hideNavBar');
    this.$store.commit('hideSettingBar');
    this.$store.commit('hideTitle');
  }

  mounted() {
    // prefill info
    // this.preFill();
    // fill in if this item
    if (Object.keys(this.item).length > 0) {
      this.fillItem(this.item);
    } else if (this.itemId && this.importItem) {
      this.$parent.$data.action = 'ModifyItem';
      this.fillItem(this.importItem);
    }

    // fill in if this template item
    else if (this.thisTemplateItem) {
      this.fillItem(this.thisTemplateItem);
    }

    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit('hideLoading');
    });
  }

  get hasThisItem(): boolean {
    return this.importItem != null;
  }

  get categoryMenu(): MenuItem[] {
    const categoryMenu: MenuItem[] = [];
    Object.entries(
      this.$store.getters[
        'itemOption/expressOrder/' + (this.isDocument ? 'getSameDayCategories' : 'getNonSameDayCategories')
      ] as Option[],
    ).forEach(([key, category]) => {
      categoryMenu.push({
        key: category.id,
        value: category.id,
        expression: this.$i18n.locale === 'cant' ? category.descriptionChi : category.descriptionEn,
      });
    });
    categoryMenu.push({
      key: 'other',
      value: 'other',
      expression: this.$t('shipment.shipmentForm.itemForm.other'),
    });
    return categoryMenu;
  }

  get dimensionMenu(): MenuItem[] {
    const dimensionMenu: MenuItem[] = [];
    Object.entries(this.$store.getters['itemOption/expressOrder/getSameDayDimensions'] as Option[]).forEach(
      ([key, dimension]) => {
        dimensionMenu.push({
          key: dimension.id,
          value: dimension.id,
          expression: this.$i18n.locale === 'cant' ? dimension.descriptionChi : dimension.descriptionEn,
        });
      },
    );
    return dimensionMenu;
  }

  get nonSameDayDimensionMenu(): MenuItem[] {
    const nonSameDayDimensionMenu: MenuItem[] = [];
    Object.entries(this.$store.getters['itemOption/expressOrder/getNonSameDayDimensions'] as Option[]).forEach(
      ([key, nonSameDayDimension]) => {
        nonSameDayDimensionMenu.push({
          key: nonSameDayDimension.id,
          value: nonSameDayDimension.id,
          expression:
            this.$i18n.locale === 'cant' ? nonSameDayDimension.descriptionChi : nonSameDayDimension.descriptionEn,
        });
      },
    );
    return nonSameDayDimensionMenu;
  }

  get weightMenu(): MenuItem[] {
    const weightMenu: MenuItem[] = [];
    Object.entries(this.$store.getters['itemOption/expressOrder/getWeights'] as Option[]).forEach(([key, weight]) => {
      weightMenu.push({
        key: weight.id,
        value: weight.id,
        expression: this.$i18n.locale === 'cant' ? weight.descriptionChi : weight.descriptionEn,
      });
    });
    return weightMenu;
  }
  get temperatureMenu(): MenuItem[] {
    const temperatureMenu: MenuItem[] = [];
    Object.entries(this.$store.getters['itemOption/expressOrder/getTemperatures'] as Option[]).forEach(
      ([key, temperature]) => {
        temperatureMenu.push({
          key: temperature.id,
          value: temperature.id,
          expression: this.$i18n.locale === 'cant' ? temperature.descriptionChi : temperature.descriptionEn,
        });
      },
    );
    return temperatureMenu;
  }

  get toPickUpStore(): boolean {
    return this.$store.getters['shipment/getToPickUpStore'];
  }

  get trackingNumber() {
    return this.inputItem.trackingNumber;
  }

  private fillItem(item: ExpressOrderItem) {
    this.inputItem = JSON.parse(JSON.stringify(item));
    if (item.categoryOptionId == 'other' || item.category) {
      this.categoryOptionComponent.setSelectedValue('other');
      this.categoryComponent.setValue(item.category || '');
      this.inputItem.category = item.category || '';
      this.inputItem.categoryOptionId = 'other';
    } else {
      this.categoryOptionComponent.setSelectedValue(item.categoryOptionId);
      this.categoryComponent.setValue('');
      this.inputItem.category = '';
      this.inputItem.categoryOptionId = item.categoryOptionId;
    }
    this.dimensionComponent.setSelectedValue(item.dimensionOptionId);

    if (this.isDocument) {
      this.weightComponent.setSelectedValue(item.weightOptionId);
      this.actualWeightComponent.setValue('');
    } else {
      this.weightComponent.setSelectedValue('');
      this.actualWeightComponent.setValue(item.actualWeight || '');
    }
    if (this.isDocument) {
      this.temperatureComponent.setSelectedValue(item.temperatureOptionId);
    } else {
      this.temperatureComponent.setSelectedValue('');
      this.inputItem.temperatureOptionId = '';
    }
    this.orderNotesComponent.setValue(item.orderNotes ? item.orderNotes : '');
    (this.$parent as ExpressOrderItemPage).savedItem = this.inputItem;
    // this.declaredCurrencyComponent.setSelectedValue(item.category);
    // this.declaredValueComponent.setValue(item.declaredCustomsValue || 0);
  }

  private async goToQrCode() {
    (this.$parent as ExpressOrderItemPage).savedItem = this.inputItem;
    await this.$router
      .push({
        name: 'qrCodeScanner',
      })
      .catch(err => {
        this.$store.commit('hideLoading');
      });
  }

  private setCategoryOption(value: string) {
    if (value !== 'other') {
      this.categoryComponent.setValue('');
      this.inputItem.category = '';
    }
  }

  // private preFill() {
  //   // this.declaredCurrencyComponent.setValue("HKD");
  // }

  private submitItemForm() {
    if (this.validateItem) {
      const submitItem = Object.assign({}, this.inputItem);
      if (submitItem.actualWeight && !isNaN(submitItem.actualWeight)) {
        submitItem.actualWeight = Number(submitItem.actualWeight);
      }

      // Cannot have property categoryOptionId if there is category
      if (submitItem.category) {
        delete submitItem.categoryOptionId;
      }
      let orders = this.$store.getters['shipment/getExpressOrderItems'];

      if (this.action == 'ModifyItem') {
        orders = orders.filter((item: ExpressOrderItem) => item.trackingNumber != this.lastTrackingNumber);
      }

      orders.push(ShipmentHelpers.updateExpressOrderItemDescription(submitItem));
      this.$store.commit('shipment/setExpressOrderItems', orders);
      this.toShipment();
    }
  }

  get validateItem() {
    return (
      this.inputItem.trackingNumber &&
      this.inputItem.dimensionOptionId &&
      (this.isDocument
        ? this.inputItem.weightOptionId
        : this.inputItem.actualWeight && !isNaN(this.inputItem.actualWeight)
        ? this.weightValidation
        : false) &&
      ((this.inputItem.categoryOptionId && this.inputItem.categoryOptionId != 'other') ||
        (this.inputItem.categoryOptionId == 'other' && this.inputItem.category)) &&
      (this.inputItem.temperatureOptionId || !this.isDocument)
    );
  }

  async toShipment() {
    await this.$router.push({ name: 'Shipment' }).catch(err => {
      this.$store.commit('hideLoading'); 
    });
  }

  get maxNumberCheck() {
    return this.inputItem.dimensionOptionId == 'D04' ? 5.0 : 100.0;
  }

  get weightValidation(): boolean {
    let result = false;
    const weight = Number(this.inputItem.actualWeight);
    switch (this.inputItem.dimensionOptionId) {
      //circle K dimension(with limited weight to 5 kg)
      case 'D04':
        result = weight <= 5.0;
        break;
      default:
        result = weight <= 100.0;
        break;
    }
    return result;
  }
}
