




































































































































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
// import { districts, areas } from "@/assets/constant";
import App from '@/App.vue';
import AddressPage from '../Address.vue';

// The MDC Components
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCTextArea from "@/components/mdcComponents/textFields/TheMDCTextArea.vue";
import { ShipmentHelpers } from "@/utils/shipmentHelpers.ts";
import * as ValidationHelpers from "@/utils/validationHelpers.ts";
import Prompt from "@/components/prompts/Prompt.vue";

@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCSelectOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCTextArea,
    Prompt,
  },
})
export default class PickUpStoreForm
  extends Vue
  implements VuePromptCaller {
  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  @Ref("contactName") contactNameComponent!: TheMDCTextFieldOutlined;
  @Ref("phoneNumber") phoneNumberComponent!: TheMDCTextFieldOutlined;


  @Ref("pickUpStoreDistrict") pickUpStoreDistrictComponent!: TheMDCSelectOutlined; //state in api /shipment/create\
  @Ref("pickUpStoreArea") pickUpStoreAreaComponent!: TheMDCSelectOutlined; //state in api /shipment/create\
  @Ref("pickUpStore") pickUpStoreComponent!: TheMDCSelectOutlined; //state in api /shipment/create\
  @Ref("external-pick-up-store-dialog") readonly externalPickUpStoreDialog!: Prompt;

  private ROOT = this.$root.$children[0] as App;

  inputAddress = {} as any;

  //using variable because select can't set value after dynamically append options.
  //static variable of area menu array need to be set before the area select component is rendered
  pickUpName = "";
  pickUpPhone = "";

  private pickUpStoreDistrict: PickUpStoreDistrict = {} as PickUpStoreDistrict;
  private pickUpStoreArea: PickUpStoreArea  = {} as PickUpStoreArea;
  private pickUpStoreDistrictMenuList: MenuItem[] = [];
  private pickUpStoreAreaMenuList: MenuItem[] = [];
  private pickUpStoreMenuList: MenuItem[] = [];
  private pickUpStoreId = "";
  private pickUpStore: PickUpStore = {} as PickUpStore;

  beforeCreate() {
    this.$store.commit("hideNavBar");
    this.$store.commit("hideTitle");
  }

  created() {
    /**
     * Need to update the select option list before the select is mounted. 
     * 
     */
    this.getPickUpStoreDistrictMenuList();
    this.pickUpStoreId = this.$store.getters["shipment/getPickUpStoreId"];
    if(this.pickUpStoreId){
      this.pickUpStore = this.getPickUpStoreById(this.pickUpStoreId);
      this.pickUpStoreDistrict = this.$store.getters["pickUpStore/getDistrictByDistrictFull"](this.pickUpStore.districtFull);
      this.getPickUpStoreAreaMenuList(this.pickUpStoreDistrict);
      this.pickUpStoreArea = this.pickUpStoreDistrict?.areas?.find((area: PickUpStoreArea)=> area.areaRank === this.pickUpStore.areaRank) || {} as PickUpStoreArea;
      this.getPickUpStoreMenuList(this.pickUpStoreArea);
    }


  }

  mounted() {
    //setAddress input field
    this.$nextTick(function () {
      this.fillPickUpInfo();
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }

  private fillPickUpInfo(){
    this.pickUpName = this.$store.getters["shipment/getPickUpName"];
    this.pickUpPhone = this.$store.getters["shipment/getPickUpPhone"];
    if(this.pickUpName.length <= 20){
      this.contactNameComponent.setValue(this.pickUpName);
    }
    if(ValidationHelpers.validatePhoneNumber(this.pickUpPhone)){
      this.phoneNumberComponent.setValue(this.pickUpPhone);
    }
    this.pickUpStoreDistrictComponent.setSelectedValue(this.pickUpStore.districtFull);
    if(this.pickUpStoreAreaMenuList.length > 0){
      this.pickUpStoreAreaComponent.setSelectedValue(this.pickUpStore.areaRank);
    }
    if(this.pickUpStoreMenuList.length > 0){
      this.pickUpStoreComponent.setSelectedValue(this.pickUpStore.id);
    }
  }

  private getPickUpStoreById(pickUpStoreId: string){
    return this.$store.getters["pickUpStore/getPickUpStoreById"](pickUpStoreId);
  }

  private setPickUpStoreDistrict(districtFull: string){
    if(this.pickUpStoreDistrict.districtFull !== districtFull && this.pickUpStore.districtFull){
      this.resetPickUpStoreArea();
    }
    this.pickUpStoreDistrict = this.$store.getters["pickUpStore/getDistrictByDistrictFull"](districtFull) || {} as PickUpStoreDistrict;

    this.getPickUpStoreAreaMenuList(this.pickUpStoreDistrict);
  }

  get pickUpStoreDistricts(){
    return this.$store.getters["pickUpStore/getDistricts"];
  }

  get noOfItem(): Item[] {
    return this.$store.getters['shipment/getExpressOrderItemsNoOfItems']|| 0;
  }

  private getPickUpStoreDistrictMenuList(){
    this.pickUpStoreDistrictMenuList =  this.pickUpStoreDistricts.map((district: PickUpStoreDistrict, index: any)=>{
        return {
          key: index,
          value: district.districtFull,
          expression: this.$i18n.locale === "cant"?district.districtChi:district.districtEn
        }
      }) || [] as MenuItem[];
    
  }

  private getPickUpStoreAreaMenuList(pickUpStoreDistrict: PickUpStoreDistrict){
    this.pickUpStoreAreaMenuList = pickUpStoreDistrict?.areas?.map((area: PickUpStoreArea, index: any)=> {
      return {
        key: index,
        value: area.areaRank,
        expression: this.$i18n.locale === "cant"?area.areaChi:area.areaEn
      }
    }) || [] as MenuItem[];

  }
  
  private getPickUpStoreMenuList(pickUpStoreArea: PickUpStoreArea){
    console.log('getPickUpStoreMenuList', pickUpStoreArea);
    this.pickUpStoreMenuList = pickUpStoreArea?.pickUpStores?.map((pickUpStore: PickUpStore, index: any)=>{
          return {
            key: index,
            value: pickUpStore.id,
            expression: pickUpStore.alias + "%%" + pickUpStore.address
          }
        }) || [] as MenuItem[];

  }

  private setPickUpStoreArea(areaRank: string){
    if(this.pickUpStoreArea?.areaRank !== areaRank && this.pickUpStoreArea.areaRank && this.pickUpStore.areaRank !== areaRank){
      this.resetPickUpStoreId();
    }
    this.pickUpStoreArea = this.pickUpStoreDistrict?.areas?.find((area)=>area.areaRank === areaRank) || {} as PickUpStoreArea;
    this.getPickUpStoreMenuList(this.pickUpStoreArea);

  }

  private setPickUpStoreId(pickUpStoreId: string){
      const selectingPickUpStore = JSON.parse(JSON.stringify(this.getPickUpStoreById(pickUpStoreId)));
    if(this.noOfItem && selectingPickUpStore.isThirdParty && pickUpStoreId){
      this.externalPickUpStoreDialog.show({
        closeBlt: this.$t("dialogs.close") as string, 
        title: this.$t("dialogs.thirdPartyNotSupportMultipleItems.title") as string, 
        msg: this.$t("dialogs.thirdPartyNotSupportMultipleItems.message",{companyNameChi: selectingPickUpStore.companyNameChi || this.$t('general.thirdParty'), companyNameEn: selectingPickUpStore.companyNameEn || this.$t('general.thirdParty')}) as string
      });
      this.$nextTick(function(){
              this.setPickUpStoreArea(this.pickUpStoreArea.areaRank);
      })
      return;
    }
    this.pickUpStoreId = pickUpStoreId;
    this.pickUpStore = JSON.parse(JSON.stringify(this.getPickUpStoreById(pickUpStoreId)));
    if(this.pickUpStore.companyId === process.env.VUE_APP_PICK_UP_STORE_COMPANY_ID_CIRCLE_K){
      this.externalPickUpStoreDialog.show({
        closeBlt: this.$t("dialogs.okay") as string, 
        title: this.$t("dialogs.reminder") as string, 
        msg: this.$t("dialogs.externalPickUpStore.circleK.message") as string
      });
    }
  }


  private resetPickUpStoreArea(){
    this.setPickUpStoreArea("");
    this.pickUpStoreAreaComponent?.setSelectedValue("");
    this.resetPickUpStoreId();
  }
  private resetPickUpStoreId(){
    this.setPickUpStoreId("");
      this.pickUpStoreComponent?.setSelectedValue("");
   

  }

  get validatePickUpStoreInfo(){
    return this.pickUpStoreId && this.pickUpName && this.pickUpPhone && ValidationHelpers.validatePhoneNumber(this.pickUpPhone);
  }

  get pickUpStoreDetail(){
    return ShipmentHelpers.displayPickUpStore(this.pickUpStore);
  }
  
  private async pickUpStoreCompleted() {
  this.$store.commit("shipment/setPickUpName", this.pickUpName);
  this.$store.commit("shipment/setPickUpPhone", this.pickUpPhone);
  this.$store.commit("shipment/setPickUpStoreId", this.pickUpStoreId);
  await this.redirectTo();
  }


  async redirectTo() {
    this.$store.commit("showLoading");
    await this.$router.push({ name: "Shipment" })
    .catch((e)=>{
      this.$store.commit("dialog/showErrorDialog", {title: this.$t("dialogs.routerErrorPrompt.title"),msg: this.$t("dialogs.routerErrorPrompt.message"), closeBlt: this.$t("dialog.close")});
    });
  }

    @Watch('$i18n.locale')
    onLocaleChange(val: string) {
        this.getPickUpStoreDistrictMenuList();
        this.getPickUpStoreAreaMenuList(this.pickUpStoreArea);
    }
    // private proceed() {
    //   this.$store.commit("showLoading")
}
