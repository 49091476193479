import { render, staticRenderFns } from "./ExpressOrderItemForm.vue?vue&type=template&id=32fdc70b&scoped=true&"
import script from "./ExpressOrderItemForm.vue?vue&type=script&lang=ts&"
export * from "./ExpressOrderItemForm.vue?vue&type=script&lang=ts&"
import style0 from "./ExpressOrderItemForm.vue?vue&type=style&index=0&id=32fdc70b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32fdc70b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kenneth/jumppoint/jumppoint-front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32fdc70b')) {
      api.createRecord('32fdc70b', component.options)
    } else {
      api.reload('32fdc70b', component.options)
    }
    module.hot.accept("./ExpressOrderItemForm.vue?vue&type=template&id=32fdc70b&scoped=true&", function () {
      api.rerender('32fdc70b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shipment/ShipmentForm/Item/ExpressOrderItemForm.vue"
export default component.exports